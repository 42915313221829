var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        { attrs: { name: "formExpense" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "show-loading": _vm.busy,
                        title: _vm.$t("Dados da despesa"),
                        "no-actions": "",
                      },
                    },
                    [
                      _c("e-bill-form", {
                        ref: "eBillForm",
                        attrs: {
                          "form-data": _vm.expenseForm,
                          "has-purchase-order":
                            !!_vm.expenseForm.purchaseOrderId,
                          "is-loading": _vm.busy,
                          "is-expense": "",
                        },
                        on: { "update-installments": _vm.updateInstallments },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "show-loading": _vm.busy,
                        title: _vm.$t("Parcelas da despesa"),
                        "no-actions": "",
                      },
                    },
                    [
                      _c("e-bill-payment-form", {
                        ref: "eBillPaymentForm",
                        attrs: {
                          "form-data": _vm.expenseForm,
                          "installments-list": _vm.getInstallments,
                          "has-purchase-order":
                            !!_vm.expenseForm.purchaseOrderId,
                        },
                        on: {
                          "add-installment": _vm.addInstallment,
                          "update-installment": _vm.updateInstallment,
                          "remove-installment": _vm.removeInstallment,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("e-crud-actions", {
        attrs: { busy: _vm.busy },
        on: { save: _vm.saveExpense, cancel: _vm.cancelExpense },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }