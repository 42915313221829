<template>
  <section>
    <FormulateForm name="formExpense">
      <b-row>
        <b-col>
          <b-card-actions
            :show-loading="busy"
            :title="$t('Dados da despesa')"
            no-actions
          >
            <e-bill-form
              ref="eBillForm"
              :form-data="expenseForm"
              :has-purchase-order="!!expenseForm.purchaseOrderId"
              :is-loading="busy"
              is-expense
              @update-installments="updateInstallments"
            />
          </b-card-actions>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card-actions
            :show-loading="busy"
            :title="$t('Parcelas da despesa')"
            no-actions
          >
            <e-bill-payment-form
              ref="eBillPaymentForm"
              :form-data="expenseForm"
              :installments-list="getInstallments"
              :has-purchase-order="!!expenseForm.purchaseOrderId"
              @add-installment="addInstallment"
              @update-installment="updateInstallment"
              @remove-installment="removeInstallment"
            />
          </b-card-actions>
        </b-col>
      </b-row>

      <!-- <b-row class="mb-2">
        <b-col>
          <b-card-actions
            :show-loading="busy"
            :title="$t('Anexos')"
            no-actions
          >
            <b-row>
              <b-col>
                <FormulateInput
                  id="bill-payment-documents"
                  v-model="documentsLocal"
                  :label="$t('Documentos')"
                  type="uploader"
                  :deletable="true"
                  :multiple="true"
                  accept=".pdf, .jpg, .png, .jpeg"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row> -->
    </FormulateForm>

    <e-crud-actions
      :busy="busy"
      @save="saveExpense"
      @cancel="cancelExpense"
    />
  </section>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { accountTypes, paymentTypes } from '@/mixins'
import EBillForm from '@/views/pages/financial/components/EBillForm.vue'
import EBillPaymentForm from '@/views/pages/financial/components/EBillPaymentForm.vue'
import ECrudActions from '@/views/components/ECrudActions.vue'

export default {
  name: 'ExpenseMaintain',
  components: {
    BCardActions,
    BCol,
    BRow,
    EBillForm,
    EBillPaymentForm,
    ECrudActions,
  },

  mixins: [paymentTypes, accountTypes],

  data() {
    return {
      busy: false,
      documentsLocal: [],
    }
  },

  computed: {
    ...mapState('pages/financial/accountsPayable/expenseMaintain', ['expenseForm', 'documents']),
    ...mapGetters('pages/financial/accountsPayable/expenseMaintain', ['getInstallments']),
    isEdit() {
      return !!this.$route.params.id
    },
    isClone() {
      return !!this.$route.params.cloneFromId
    },
  },

  async mounted() {
    if (this.isEdit || this.isClone) {
      try {
        this.busy = true
        await this.stFetchAccountPaymentById(
          this.$route.params.id || this.$route.params.cloneFromId
        )
        if (this.isClone) {
          this.CLEAN_STATE_TO_CLONE()
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        setTimeout(() => {
          this.busy = false
        }, 300)
      }
    }
  },

  methods: {
    ...mapActions('pages/financial/accountsPayable/expenseMaintain', {
      stCleanExpense: 'cleanState',
      stSetDocuments: 'setDocuments',
      stAddInstallment: 'addInstallment',
      stUpdateInstallment: 'updateInstallment',
      stRemoveInstallment: 'removeInstallment',
      stSaveExpense: 'saveExpense',
      stFetchAccountPaymentById: 'fetchAccountPaymentById',
      stUpdateInstallmentsValue: 'updateInstallmentsValue',
    }),
    ...mapMutations('pages/financial/accountsPayable/expenseMaintain', ['CLEAN_STATE_TO_CLONE']),

    async saveExpense() {
      if (!this.$refs.eBillForm.isValid()) {
        this.showInvalidDataMessage()
        return
      }
      if (!this.$refs.eBillPaymentForm.isValid()) {
        this.showInvalidDataMessage({
          message: this.$t('Não há parcelas. Caso seja 1 pagamento, adicione apenas 1 parcela'),
        })
        return
      }

      try {
        this.busy = true

        await this.stSaveExpense()

        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })
        this.$router.push({ name: 'accounts-payable-list' })
      } catch (error) {
        this.showError({ error, message: this.$t('Não foi possível concluir a operação') })
      } finally {
        this.busy = false
      }
    },

    addInstallment(installmentData) {
      this.stAddInstallment({ formData: installmentData })
    },
    updateInstallment(installmentData) {
      this.stUpdateInstallment({ formData: installmentData })
    },
    removeInstallment(item) {
      this.stRemoveInstallment({ id: item.id || item.localId })
    },

    async updateInstallments() {
      await this.stUpdateInstallmentsValue()
    },

    cancelExpense() {
      this.stCleanExpense()
      this.$router.back()
    },

    async uploadDocuments() {
      console.log('documents upload to return url', this.documents)
      return []
    },
  },
}
</script>

<style></style>
